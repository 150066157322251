<template>
  <div
    class="minecraft you-found-me fill-height background d-flex justify-center align-center"
    :style="{ 'background-image': `url('${require('@/assets/dirt.png')}')` }"
  >
    <div class="fon"></div>
    <div class="index-9 text-center">
      <img :src="require('@/assets/logo.png')" alt="" style="max-height: 150px" class="mb-3">
      <div>Готовы приступить к игре?</div>
      <div>Копируйте айпи и присоединяйтесь к нам на Java Edition 1.16+!</div>
      <v-text-field label="Наш айпи адрес" value="mc.minetown.ru" :hint="hint" persistent-hint v-model="ip" @click="copyText" readonly outlined dense class="mt-4" solo id="ip"></v-text-field>
    </div>
  </div>
</template>

<style scoped>
@font-face {
  font-family: "Minecraftia";
  src: local("Minecraftia"), url("~@/assets/fonts/minecraftia.ttf") format("truetype");
}

.background {
  background-repeat: repeat;
  background-size: 64px;
}

.background * {
  font-family: 'Minecraftia', sans-serif !important;
}

.index-9 {
  z-index: 9;
}
.fon {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #1d1d1d;
  opacity: 0.86;
}
</style>

<script>
import copy from 'copy-to-clipboard';

export default {
  name: "Minecraft",
  data: () => {
    return {
      d: false,
      ip: "mc.minetown.ru",
      hint: "Нажмите, чтобы скопировать айпи адрес"
    };
  },
  methods: {
    copyText() {
      this.selectText("ip");
      this.hint = "Адрес скопирован";
      copy(this.ip);
      setTimeout(() => {
        this.hint = "Нажмите, чтобы скопировать айпи адрес";
      }, 5000);
    },
    selectText(node) {
      node = document.getElementById(node);
      node.select();
    }
  }
};
</script>
