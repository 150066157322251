<template>
  <div class="rules">
    <v-container class="mb-4">
      <v-card
        v-for="(section, sectionNumber) in rules.rules"
        :key="sectionNumber"
        class="ma-2"
        outlined
      >
        <v-card-text>
          <div>Раздел {{ sectionNumber }}</div>
          <p class="text-h4 text--primary">{{ section.name }}</p>
          <div class="text--primary">
            <v-card tile elevation="12">
              <v-card-text
                two-line
                v-for="(paragraph, paragraphNumber) in section.paragraphs"
                :key="paragraphNumber"
              >
                <div v-if="!paragraph.subheader">
                  <div>
                    <b>{{ paragraphNumber }}</b> {{ paragraph.text }}
                  </div>
                  <div v-if="paragraph.punishment">
                    <span class="red--text darken-2">Наказание:</span> {{ paragraph.punishment }}
                  </div>
                </div>
                <div v-else>
                  <v-card class="pa-2 orange darken-2">
                    <h4>
                      <b>{{ paragraphNumber }}</b>
                      {{ paragraph.text.replaceAll(":", "") }}
                    </h4>
                  </v-card>
                </div>
              </v-card-text>
            </v-card>
          </div>
        </v-card-text>
      </v-card>
    </v-container>

    <Footer></Footer>
    <scroll-fab />
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import axios from "axios";
import { getApiUrl } from "@/lib/utils";
import ScrollFab from "@/components/ScrollFab";
export default {
  components: { ScrollFab, Footer },
  data: () => {
    return {
      rules: {},
      search: ""
    };
  },
  async mounted() {
    this.rules = await (await axios.get(getApiUrl("rules.json"))).data;
    console.log(this.rules);
  }
};
</script>
