<template>
  <div
    class="minecraft you-found-me fill-height background d-flex justify-center align-center"
    :style="{ 'background-image': `url('${require('@/assets/dirt.png')}')` }"
  >
    <v-snackbar
        v-model="snackbar"
    >
      Включи аудио :3

      <template v-slot:action="{ attrs }">
        <v-btn
            color="primary"
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Окей!
        </v-btn>
      </template>
    </v-snackbar>
    <div class="fon"></div>
    <audio
      :src="require('@/assets/audio/wethands.mp3')"
      autoplay
      id="player"
      loop
    ></audio>
    <div class="index-9 text-center">
      <v-icon size="128" color="red" @click="togglePlayer()">mdi-heart</v-icon>
      <h2>Спасибо, что ты с нами!</h2>
      <div>Просто наслаждайся моментом. Ты молодец!</div>
    </div>
  </div>
</template>

<style scoped>
.background {
  background-repeat: repeat;
  background-size: 64px;
}
.index-9 {
  z-index: 9;
}
.fon {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #1d1d1d;
  opacity: 0.86;
}
</style>

<script>
export default {
  name: "Minecraft",
  data: () => {
    return {
      player: null,
      snackbar: true
    };
  },
  mounted() {
    this.player = document.getElementById("player");
    this.player.volume = 0.5;
    this.snack
  },
  methods: {
    togglePlayer() {
      if (this.player.paused) {
        this.player.play();
      } else {
        this.player.pause();
      }
    }
  }
};
</script>
